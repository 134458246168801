@import url('https://fonts.googleapis.com/css?family=Cardo:400,700|Oswald|Material+Icons');

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  color: #252525;
  height: 100%;
}

body {
  font-family: 'Cardo', serif;
}

main {
  height: 100%;
  padding: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

#terminal {
  padding: 5px;
  background-color: rgb(27, 29, 30);
}

#nohost-server {
  width: 100%;
  height: 100%;
}

.app-window {
  line-height: 1;
  display: grid;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: minmax(50px, 50px);
}

.title-bar {
  font-size: 1.3em;
  background-color: lightgray;
  border: 1px solid darkgray;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.title-bar i {
  margin-right: 5px;
  cursor: pointer;
}

#drag-drop {
  border: dashed 2px #AEAEAE;
  border-top: 0;
  color: #969696;
  position: relative;
}

#drag-drop.drag {
  border: dashed 2px #5EBF7C;
  border-top: 0;
  background-color:rgba(94,191,124,0.1)
 }

#drag-drop h3 {
  position: absolute;
  padding-top: 60px;
  top: 35px;
  text-align: center;
  width: 100%;
  background-image: url(upload-cloud.svg);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 70px;
}

.xterm {
  height: 100%;
}

.xterm-screen {
  height: 100%!important;
}